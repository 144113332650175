import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import saveTheDate from '../../../assets/images/beltech_banner.webp';
import beltechLaunch from '../../../assets/images/beltech_launch.webp';
import { TwitterTimeline } from '@kainos-applied-innovation/convention-frontend-components';

const HomePage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.Home.Title');
  }, []);

  function navigateToCallingSpeakers() {
    window.open('https://irishecho.wufoo.com/forms/p1yp1gf30i8cby0/', '_blank');
  }

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
        </div>
        <img
          className="con-save-date-image"
          src={saveTheDate}
          alt="Beltech 2025. Save the date, Thursday 10th April 2025 and Friday 11th April 2025"
        />
        <div className="con-calling-speakers-card__container">
          <button className="con-link-button con-heading__m--centered" onClick={navigateToCallingSpeakers}>Calling Speakers!</button>
        </div>
        <p className="con-heading__s--margin-bottom-1">
          {t('Pages.CallingSpeakers.Description.BeltechPlatform')}
        </p>
        <p className="con-heading__s--margin-bottom-1">
          {t('Pages.CallingSpeakers.Description.Beltech2025')}
        </p>
        <p className="con-heading__s--margin-top-1">
          {t('Pages.CallingSpeakers.Description.AITitle')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.AIDescription1')}
        </p>
        <p className="con-heading__s--margin-top-1">
          {t('Pages.CallingSpeakers.Description.SecurityTitle')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.SecurityDescription1')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.SecurityDescription2')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.SecurityDescription3')}
        </p>
        <p className="con-heading__s--margin-top-1">
          {t('Pages.CallingSpeakers.Description.DeveloperTitle')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.DeveloperDescription1')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.DeveloperDescription2')}
        </p>
        <p className="con-heading__s--margin-top-1">
          {t('Pages.CallingSpeakers.Description.SoftwareTitle')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.SoftwareDescription1')}
        </p>
        <img
          className="con-save-date-image"
          src={beltechLaunch}
          alt="client"
        />
        <p className="con-heading__s--margin-top-1">
          {t('Pages.CallingSpeakers.Description.ThursdayApril10th')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.ThursdayApril10thDescription1')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.ThursdayApril10thDescription2')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.ThursdayApril10thDescription3')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.ThursdayApril10thDescription4')}
        </p>
        <p className="con-heading__s--margin-top-1">
          {t('Pages.CallingSpeakers.Description.FridayApril11th')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.FridayApril11thDescription1')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.CallingSpeakers.Description.FridayApril11thDescription2')}
        </p>
        <TwitterTimeline accountName={'beltech2023'} />
      </div>
    </div>
  );
};

export default HomePage;
